#reviewform {
  background-color: #fff;
  margin-top: 20px;
}
#reviewform .wh-form__buttongroup {
  max-width: 300px;
}
#reviewform .remark {
  border: 1px dotted #aaa;
  padding: 10px;
  margin: 40px 0 20px;
}

.reviewbtn {
  margin-bottom: 20px;
}

html.reviewpage .block,
html.webshop--isshopreviews .block {
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

#showreviewstats {
  color: #337ab7;
  cursor: pointer;
}
#showreviewstats:hover {
  color: inherit;
}

#reviewpagetotals {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#reviewpagetotals .rating-stars {
  margin: 0 4px 10px 10px;
}
#reviewpagetotals .mm-rating-txt {
  line-height: 19px;
  margin-top: 0;
  margin-bottom: 10px;
}

#rating-stats {
  line-height: 19px;
  transition: height 0.3s;
}
#rating-stats.show {
  height: 150px;
}
#rating-stats .count {
  display: inline-block;
  padding: 1px 0 0 7px;
}
#rating-stats .count:before {
  content: "(";
}
#rating-stats .count:after {
  content: ")";
}

.reviewitem__balloon {
  background: #eee;
  padding: 10px 10px 0;
  font-style: italic;
  overflow: hidden;
}

.reviewitem__balloonarrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #eee;
  margin: 0 0 0 30px;
}

.reviewitem__author {
  font-weight: 700;
}

.reviewitem__starcontainer {
  display: inline-block;
  position: relative;
  height: 23px;
}

#rating-tooltip .rating-stars {
  position: relative;
  display: inline-block;
  width: 107px;
  height: 19px;
}

li.webshop-pagenav--current {
  background: #2786e8;
}