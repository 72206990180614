footer #footer__logos {
  height: 175px;
  overflow: hidden;
  padding: 20px 0;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
}
footer #footer__logos img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.3s ease;
}
footer #footer__logos img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
footer #ondermenu {
  margin-bottom: 20px;
  padding: 20px 0;
  background: #000000;
  color: #fff;
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
}
footer .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
footer .footer__widget ul li {
  display: table-row;
}
footer .footer__widget ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #fff;
}
footer .bottom__logos {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 25px;
}
footer .bottom__logos img {
  max-height: 50px;
  width: auto;
  max-width: 100px;
  margin: 0 20px 20px 0;
}
footer .bottom__logos .payment__logos {
  display: inline;
}
footer .bottom__logos .payment__logos img {
  max-height: 41px;
}
footer .bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
footer .bottomnav ul li {
  display: inline-block;
}
footer .bottomnav ul li a {
  color: #58595b;
}
footer .bottomnav ul li + li:before {
  content: "|";
  padding: 0 6px;
  display: inline-block;
}
footer h5 {
  color: #fff;
  display: block;
  margin: 0 0 10px;
  padding: 0 0 10px;
}
footer a, footer h4 {
  color: #fff;
}
footer a:hover {
  color: #fff;
}
footer #social-media {
  margin: 20px 0 0;
}
footer #social-media svg {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
}
footer .form-control {
  background: #4f4f4f;
  border: 0;
  color: #fff;
}
footer .form-control::placeholder {
  color: #fff;
}