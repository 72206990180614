.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
aside
{
  border-bottom:1px solid #e5e5e5;
  padding-bottom: 20px;
}

/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  padding-bottom: 5px;
}
.productfilter__activetitle
{
  margin-bottom: 5px;
}
.productfilter__activelabel
{
  background-color: #eeeeee;
  padding: 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 3px;
}
.productfilter__activeremove:before
{
  content: "x";
  float: right;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
}
.productfilter--optionlist
{
  max-height: 225px;
  overflow-x: hidden;
  overflow-y: auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter + .productfilter
{
  border-top: 1px solid #e5e5e5;
}
.productfilter:last-child
{
  padding-bottom: 0;
}
.productfilter-title
{
  font-weight: bold;
  padding-bottom: 10px;
  padding: 10px 0;
  display: block;
  color:#000;
}

.productfilter-title[data-toggle="collapse"]:after {
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter-title[data-toggle="collapse"].collapsed:after {
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-content: flex-start;
}
.productfilter--optionlist li input
{
  margin-top: -9px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
}
.productfilter--select
{
  width: 100%;
  padding: 5px;
}
@media( max-width: 992px)
{
  .widget-productfilter
  {
    padding:5px 0 0 0;
    background:none;
    box-shadow: none;
    -webkit-box-shadow: none;
    border:none;
    overflow: visible;
  }
  .productfilter_mobilecontainer
  {
    background: #fff;
    overflow: hidden;
    margin-top: 5px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  }
  .productfilter__activetitle
  {
    margin-top: 5px;
  }
  .productfilter-title {
      padding: 10px;
      cursor: pointer;
  }
  .productfilter + .productfilter
  {
      border-top: 1px solid #eee;
  }
  .webshop--ishomepage .widget-mobileproductfilter
  {
    display: none;
  }
  .collapsebtn
  {
    position: relative;
    padding-right: 30px;
    position: relative;
  }
  .productfilter--optionlist
  {
    padding: 0px 10px;
  }
  .filtertype--checkbox
  {
    padding: 0 10px 10px 10px;
  }
  .filtertype--price
  {
    padding: 0px 20px;
  }
  a.collapsebtn[aria-expanded="false"]:before
  {
    content: "Filter";
  }
  a.collapsebtn[aria-expanded="true"]:before
  {
    content: "Verberg filter";
  }
  a.collapsebtn[aria-expanded="false"]:after
  {
    font-family: FontAwesome;
    content: "\f107";
    position: absolute;
    right:10px;
  }
  a.collapsebtn[aria-expanded="true"]:after
  {
    font-family: FontAwesome;
    content: "\f106";
    position: absolute;
    right:10px;
  }
  .widget-productfilter .aside__title
  {
    display: none;
  }
  .productfilter-title[data-toggle="collapse"]:after
  {
    content: "\f106";
  }
  .productfilter-title[data-toggle="collapse"].collapsed:after {
    content: "\f105";
  }
  .productfilter__activelabel {
      padding: 5px 30px 5px 10px;
      position: relative;
      display: inline-block;
      margin-right: 3px;
  }
  .productfilter__activeremove:before {
      position: absolute;
      right: 9px;
      top: 4px;
  }
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
/**/
.sidereview .rating-stars-count
{
  padding: 5px 10px;
  display: block;
}
.sidereview .ratingcomment
{
  clear: both;
  margin-top: 5px;
  background-color: #eee;
  position: relative;
}
.sidereview .ratingcomment:after
{
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 100%;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.sidereview .ratingcomment > *
{
  padding: 15px 10px;
}

/**/
.widget-inlinemultiplereviews--bgwhite
{
  background-color: #fff;
  padding: 20px;
}

/**/
.widget-inlinereview__comment
{
  background-color: #eee;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
  .widget-inlinereview__comment > *:before
, .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
}

/**/
.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
/**/
.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}


/* contact*/
.widget-contact h4
{
  font-size: 18px;
  font-weight: 600;
}
.widget-contact ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contact li
{
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}
  .widget-contact li > a
, .widget-contact li > .jivo-btn
{
  display: block;
  position: relative;
  padding-left: 27px;
  color: #337ab7;
  cursor: pointer;
}
  .widget-contact li > a:hover
, .widget-contact li > .jivo-btn:hover
{
  text-decoration: underline;
}
.widget-contact .fa
{
  position: absolute;
  left: 0;
  right: 0;
  font-size: 19px;
  color: #4dadd8;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* group for quicklink widgets */
.widget-widgetgroup
{
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
.widget-widgetgroup > li
{
  flex: 1 1 50%;
  padding: 0 0 20px 30px;
}
.widget-widgetgroup > li > .widget
{
  height: 100%;
  margin-bottom: 0;
}
@media( max-width: 600px )
{
  .widget-widgetgroup
  {
    display: block;
    margin-left: 0;
  }
  .widget-widgetgroup > li
  {
    flex: none;
    width: 100%;
    padding-left: 0;
  }
}


.widget-quicklinks
{
  box-shadow: 0 0 7px rgba(0,0,0,0.2);
  color: #337ab7;
}
.widget-quicklinks__title
{
  font-size: 16px;
  padding: 8px 20px;
  position: relative;
  border-bottom: 1px solid #c6c1be;
  display: block;
}
.widget-quicklinks__title.withicon
{
  padding-left: 45px;
}

.widget-quicklinks .fa
{
  color: #4dadd8;
  position: absolute;
  top: 9px;
  left: 20px;
  display: inline-block;
}
  .widget-quicklinks__title
, .widget-quicklinks a
{
  color: inherit;
}
.widget-quicklinks a:hover
{
  color: #584a45;
  text-decoration: underline;
}
.widget-quicklinks ul
{
  list-style-type: none;
  margin: 0;
  padding: 10px 20px;
}
.widget-quicklinks ul > li
{
  display: block;
  position: relative;
  padding-left: 15px;
}
.widget-quicklinks ul > li:before
{
  font-family: FontAwesome;
  display: inline-block;
  color: #4dadd8;
  position: absolute;
  top: 0;
  left: 0;
  content: "\f105";
  font-size: 16px;
}

.widget-map
{
  padding-top: 75%;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
  cursor: pointer;
}
.widget-map:after
{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-twocol__row__col .widget-map
{
  padding-top: 100%;
}
.widget-map__gmap
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.widget-map__gmap .wh-infowindow
{
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-top: -40px;
  margin-left: -20px;
}
.widget-map__gmap .wh-infowindow > *
{
  display: block;
}
.widget-map__gmap .wh-infowindow .title
{
  padding-top: 5px;
  font-size: 16px;
  line-height: 16px;

  width: 300px;
  margin-left: -130px;
}


  .twocol .row:after
, .twocol .col-md-6:after
{
  content: "";
  clear: both;
  display: block;
}

.twocol .col-md-6
{
  width: 50%;
  float: left;
}

@media(max-width: 768px)
{
  .twocol .col-md-6
  {
    padding-right: 0;
    padding-left: 0;
    float: none;
    width: 100%;
  }
}


.categorylist__list,
.subcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.subcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem
{
  font-size: 15px;
}
.categorylist__listitem
{
  list-style-type: none;
  padding: 2px 0;
}
.subcategorylist__listitem
{
  list-style-type: none;
  padding: 2px 0px 0 10px;
}
li.selected > .subcategorylist__list
{
  display: block;
}
li.selected > .subcategorylist__listitemlink {
  font-weight: 400;
  text-decoration: underline;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink
{
  color: #58585a;
}