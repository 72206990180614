/* light, normal, semi-bold, bold */
html,
body
{
  color: #000;
  font-family: "Roboto",sans-serif;
  font-weight: 300;
  font-display: swap;
}

body
{
  font-size: 16px;
  line-height: 24px;
}
strong ,
b
{
  font-weight:700;
}
h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{
    color: #000;
    font-family: 'Exo', sans-serif;
    font-weight:400;
    margin-bottom: 10px;
    line-height: 120%;
    font-display: swap;
}
h1 {
    font-size: 36px;
}
h2 {
    font-size: 26px;
}

h2.heading2_checked:before
{
  content: '\F00C';
  display: inline-block;
  font-family: fontAwesome;
  color: #519872;
  font-size: 18px;
  padding-right: 5px;
}

h3 {
    font-size: 21px;
}

h1.heading1_centered,
h2.heading2_centered,
h3.heading3_centered {
  text-align: center;
}

ul.unordered,
ol.ordered  {
  margin-bottom:20px;
}
ul.unordered li,
ol.ordered li{
  margin-left: 25px;
  line-height: 26px;
}
ul.checkmarks,
ul.pros,
ul.cons  {
    padding: 0;
    display: table;
    list-style: none;
}
ul.checkmarks li,
ul.pros li,
ul.cons li {
    display: table-row;
}
ul.checkmarks li:before,
ul.pros li:before,
ul.cons li:before {
    content: '\f00c';
    width: 1.3em;
    font-size: 16px;
    line-height: 24px;
    font-family: FontAwesome;
    display: table-cell;
    padding-left: 0;
    color: #519872;
}
ul.pros li:before
{
    content: '\f055';
}
ul.cons li:before 
{
    color: #999;
    content: '\f056';
}
.button a,
.button_centered a{
    display: inline-block;
    width: auto;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .5rem 1.2rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #2786e8;
    border-color: #2786e8;
    color: #000;
}
.button a:hover,
.button a:focus,
.button a:active,
.button a.active,
.button a.active:hover,
.button_centered a:hover,
.button_centered a:focus,
.button_centered a:active,
.button_centered a.active,
.button_centered a.active:hover   {
  background-color: #2b95ff;
  border-color: #2b95ff;
  text-decoration: none;
}
.button_centered a {
    width: max-content;
    margin: 0 auto;
    display: block;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}
p.normal a {
  font-weight: bold;
}
a {
    color: #2786e8;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__table .wh-rtd__img
{
  max-width: calc(100vw - 40px);
}
.wh-rtd__img--floatleft
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

.wh-rtd__tablewrap
{
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  border-top: 0;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  padding-left: 15px;
  background-color: #584a45;
}
.wh-rtd--hascolheader ~ tr > td:first-child
{
  padding-left: 15px;
}

.wh-rtd__table * + *
{
  padding-left: 15px;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding-top: 5px;
  padding-bottom: 5px;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
/*
.wh-rtd__table tr + tr > td
{
  border-top: 1px solid #dadada;
}
*/

.usps__hearplanet
{
  background:#fff;
  padding:15px 0 3px 0px;
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 60px rgba(0,0,0,.15);
  -moz-box-shadow: 0 0 60px rgba(0,0,0,.15);
  -webkit-box-shadow: 0 0 60px rgba(0,0,0,.15);
  font-size: 15px;
  font-weight: 400;
}
.categoryusps__hearplanet
{
  background:#fff;
  padding:15px 0 3px 0px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 15px;
  font-weight: 400;
  margin:30px 0;
}
.usps_container
{
  min-height: 35px;
}
.usps_container i.fa-check
{
  color:#8fc13e;
}
.usps__hearplanet .icon_wrapper
{
  display: block;
  float:left;
  width: 45px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 10px;
}
.usps__hearplanet i
{
  color:#8fc13e;
  font-size: 21px;
  vertical-align: middle;
  line-height: 21px
}
i.fa-star
{
  color:#e98e24;
}
.usps__hearplanet a
{
  color:#58585a;
}
.usps__hearplanet .usps__reviewaggregate
{
  color:#8fc13e;
  font-size:21px;
}
.usps__hearplanet .usps__reviewaggregate strong
{
  font-weight: 600
}

.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}
.widget-statement {
  margin:75px 0!important;
}
.widget-statement img {
  max-width: 290px;
  float: right;
}
.widget-statement h2
{
  font-size: 41px;
  font-weight:300;
  margin-bottom: 0;
  line-height: 32px;
}
.widget-statement .col-lg-4
{
  border-right:3px solid #8fc13e;
}
.widget-statement h2 small
{
  font-size: 26px;
  font-weight:300;
  color:#58595b;
}
.categoryblock
{
  text-align: center;
  min-height: 390px;
  display: block;
  position: relative;
}
@media(max-width: 565px)
{
  .categoryblock
  {
    min-height: 200px;
  }
  .categoryblock__title
  {
    font-size: 21px;
  }
}
.categoryblock.telefoons {
  background: url(../web/img/telefoons.jpg) no-repeat center top;
  background-size: cover;
}
.categoryblock.wekkers {
  background: url(../web/img/wek-en-waarschuwing.jpg) no-repeat center top;
  background-size: cover;
}
.categoryblock.televisie {
  background: url(../web/img/audio-en-televisie.jpg) no-repeat center top;
  background-size: cover;
}
.categoryblock.onderhoud {
  background: url(../web/img/onderhoud-hoortoestellen.jpg) no-repeat center top;
  background-size: cover;
}
.categoryblock a{
  display: block;
}

.categoryblock:before
{
  position: absolute;
  content:" ";
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: none;
  z-index:0;
}

.categoryblock:hover:before
{
  display: block;
}

.green:before {
  background-color: rgba(39,134,232,0.5);
}

.categoryblockgrid a:hover
{
  text-decoration:none;
  cursor: pointer;
}
.categoryblock__title {
  font-family: 'Exo', sans-serif;
  color:#fff;
  font-size:24px;
  text-align:center;
  position: relative;
}
.no-gutters.categoryblockgrid
{
  margin-right: 1px!important;
  margin-left: 1px!important;
}
.no-gutters.categoryblockgrid > .col,
.no-gutters.categoryblockgrid > [class*="col-"]
{
  padding-right: 1px!important;
  padding-left: 1px!important;
  margin-bottom: 2px;
}

.twocol-review .col-md-6:nth-child(2)
{
   background: url(../web/img/twocol_review_bg.jpg) no-repeat right top;
   background-size: cover;
   padding:100px 0;
}
.twocol-review .col-inner-left
{
  max-width: 570px;
  float:right;
  padding-right: 50px;
  padding-left:0;
}
.twocol-review .col-inner-right
{
  max-width: 455px;
  padding-left:100px;
}
.twocol-review .rating-stars
{
  background-image: url(../web/img/stars-review-grey.png);
  display: inline-block;
  height: 22px;
  position: relative;
  width: 117px;
}
.twocol-review .rating-stars .inner
{
  background-image: url(../web/img/stars-review-gold.png);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.twocol-review .col-inner-right .widget-inlinereview__comment
{
  background-color: #000000;
  color:#fff;
  margin-top: 15px;
}
.twocol-review .col-inner-right .widget-inlinereview__comment:after
{
  border-top: 10px solid #000000;
}
.twocol-review h2
{
  color:#fff;
}
.twocol-review .reviewaggregate
{
  color:#fff;
  font-size:21px;
}
.twocol-review .widget-inlinereview__meta
{
  color:#fff;
}
@media (max-width: 1199px)
{
  .twocol-review .col-inner-left
  {
      padding-left: 13%;
  }
}
@media (max-width: 600px)
{
.twocol-review .col-md-6:nth-child(2)
  {
     padding:50px 0;
  }
  .twocol-review .col-inner-right
  {
     padding:13%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px)
{
  .twocol-review .col-inner-right
  {
    max-width: 360px;
    padding-left: 60px;
  }
  html.webshop--ishomepage main .twocol .col-md-6:nth-child(2)
  {
    max-width: 360px;
    padding-left: 40px!important;
    padding-left: 30px!important;
    padding-top: 30px;
  }
  .usps__hearplanet .usps__starwrapper
  {
    display: none;
  }
}
@media (max-width: 576px)
{
  .usps__hearplanet
  {
    font-size: 12px;
  }
  .usps__hearplanet img
  {
    max-width: 26px;
  }
  .usps__hearplanet .usps__reviewaggregate
  {
    font-size: 16px;
  }
  .usps__hearplanet .usps__starwrapper
  {
    display: none;
  }
  .widget-statement h2
  {
    font-size: 28px;
    line-height: 27px;
  }
  .widget-statement h2 small
  {
      font-size: 20px;
  }
}
