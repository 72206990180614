header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  transition: all 0.2s ease;
  will-change: transform, height, background-color; /* Optimize rendering */
}
header.stickynav {
  position: sticky;
  top: 0;
}
header.stickynav .logo {
  margin: 0;
  height: 68px;
}
header.stickynav .logo img {
  max-width: 250px;
}
header.stickynav .topmidpanel .topsearchform {
  margin-top: 15px;
  transition: all 0.2s;
}
header.stickynav .topmidpanel .topsearchform .input-group-lg > .form-control,
header.stickynav .topmidpanel .topsearchform .input-group-lg > .input-group-append > .btn {
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
}
header.stickynav .topmidpanel .topsearchform input::placeholder {
  font-size: 14px;
}
header.stickynav .toprightpanel {
  margin-top: 0px;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .logo {
  display: inline-block;
  margin: 10px 0;
  height: 85px;
  transition: all 0.2s;
}
header .logo > img {
  height: 100%;
  width: auto;
  max-width: 320px;
  transition: all 0.2s;
}
header .topnav {
  margin-bottom: 0;
}
header .topnav a {
  padding: 0 0.5rem;
}
header .toprightpanel {
  margin-top: 15px;
}
header .toprightpanel .myaccount {
  margin-right: 10px;
}
header .toprightpanel i {
  color: #2786e8;
}
header .toprightpanel a {
  color: #000000;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .badge {
  background: #000;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: -12px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .toprightpanel .dropdown-item:hover, header .toprightpanel .dropdown-item:focus {
  background-color: #000000;
  color: #fff;
}
header .topmidpanel .topsearchform {
  margin-top: 30px;
  white-space: nowrap;
  transition: 0.2s;
}
header .topmidpanel .topsearchform input {
  border-left: 1px solid #7F7F7F;
  border-top: 1px solid #7F7F7F;
  border-bottom: 1px solid #7F7F7F;
  border-right: 0;
  outline: none;
  -webkit-appearance: none;
  max-width: 350px;
}
header .topmidpanel .topsearchform input::placeholder {
  color: #999;
  font-weight: 300;
  font-size: 16px;
}
header .topmidpanel .topsearchform button {
  border-right: 1px solid #7F7F7F;
  border-top: 1px solid #7F7F7F;
  border-bottom: 1px solid #7F7F7F;
  color: #2786e8;
}
@media (max-width: 1199px) {
  header .toprightpanel {
    border-right: 0 none;
    border-left: 0 none;
    background-color: transparent;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
  }
  header .toprightpanel:before,
  header .toprightpanel .topnav {
    display: none;
  }
  header .toprightpanel .basket .label {
    display: none;
  }
  header .topsearchform .input-group > .input-group-append > .btn,
  header .topsearchform .input-group-lg .form-control {
    font-size: 1rem;
    border: none;
  }
  header .topsearchform .btn-link {
    color: #58585a;
    background-color: #fff;
  }
}
@media (max-width: 767px) {
  header .logo {
    margin: 10px 0;
    height: 56px;
  }
  header .logo > img {
    max-width: 230px;
  }
  header.stickynav {
    position: sticky;
    top: 0;
    z-index: 3;
  }
  header.stickynav .logo {
    margin: 0;
    height: 68px;
  }
  header.stickynav .logo img {
    max-width: 200px;
  }
  header.stickynav .toprightpanel {
    margin-top: 0px;
  }
  header .stickynav {
    position: inherit;
  }
  header .toprightpanel {
    padding-top: 0;
  }
}
@media (max-width: 565px) {
  header #topsearchform {
    max-width: 240px;
  }
  header .navbar-brand {
    margin-right: 0;
  }
  header .navbar-toggler {
    padding: 6px 4px;
  }
  header .logo > img {
    max-width: 180px;
  }
  header.stickynav .logo img {
    max-width: 180px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .topleftpanel {
    text-align: center;
  }
  header .toprightpanel {
    margin-top: 0;
    text-align: right;
  }
}
@media (min-width: 320px) and (max-width: 472px) {
  header.stickynav .logo > img,
  header .logo > img {
    max-width: 170px;
  }
  header .toprightpanel .fa-phone {
    font-size: 27px;
    position: relative;
    left: -5px;
    top: 7px;
  }
  header .header__phonenumber {
    display: none;
  }
  header .navbar-toggler-text {
    display: none;
  }
}

.navbar-dark {
  clear: both;
}

.navbar {
  padding: 0;
}

.navbar-brand {
  font-size: 1rem;
}

.navbar-toggler {
  border: none;
}

#navbar-wrapper {
  clear: both;
  background-color: #000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* */
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu {
  border-radius: 0;
  top: 93%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li:hover > a, #mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li:focus > a {
  background-color: #000000;
  color: #fff;
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li a.active {
  background-color: #000000;
  color: #fff;
}
@media (min-width: 1200px) {
  #mainnav .navbar-nav .nav-item.dropdown:hover > .dropdown-menu, #mainnav .navbar-nav .nav-item.dropdown:focus > .dropdown-menu {
    display: block;
  }
}
#mainnav .navbar-nav li ul li ul.dropdown-menu {
  left: 100%;
  top: -3px !important;
}
#mainnav .navbar-nav .nav-link {
  color: #fff;
}