@charset "UTF-8";
.selectlist {
  display: inline-block;
  cursor: default;
  vertical-align: middle;
  height: 28px;
}
.selectlist--replaced {
  display: none;
}
.selectlist__area {
  height: 100%; /* This area */
  overflow: hidden;
}
.selectlist__control {
  height: 100%;
  position: relative;
}
.selectlist__items {
  /*min-height:100%;*/
}
.selectlist__items--open {
  position: fixed;
  cursor: default;
  overflow: auto;
}
.selectlist__item--disabled:first-child {
  display: none;
}
.selectlist__current {
  position: relative;
  padding: 0 5px;
  border: 1px solid #ced4da;
  border-radius: 6px;
}
.selectlist__current, .selectlist__item, .selectlist__optgroup {
  padding: 4px 24px 4px 4px;
}
.selectlist__current:after {
  content: "⇩";
  float: none;
  position: absolute;
  right: 8px;
  top: 4px;
}
.selectlist__items {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 5px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1 !important;
}
.selectlist__item {
  background: #ffffff;
  cursor: pointer;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.selectlist__item:hover {
  background: #8fc13e;
  color: #ffffff;
  border-radius: 6px;
}
.selectlist__item, .selectlist__optgroup {
  min-height: 28px;
}