html.sidebarjs--open {
  overflow-y: hidden;
}

#product_listing {
  margin-bottom: 50px;
  padding: 0 15px;
}
@media (max-width: 565px) {
  #product_listing {
    margin-bottom: 50px;
    background-color: #f5f5f5;
    padding: 15px;
    margin-right: -15px;
  }
}
#product_listing .productsgrid__product--hidden {
  display: none;
}
#product_listing .webshop-products--ordering-container .form-control {
  width: auto;
  display: inline-block;
  border-color: #7F7F7F;
}

#productsgrid_anchor {
  position: absolute;
  top: -100px;
  left: 0;
}

html.page--categories #product_listing {
  margin-left: -15px;
  margin-right: -15px;
}

.page--categories .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}

.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  color: #000000;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

@media (max-width: 565px) {
  .categorygrid {
    margin-bottom: 50px;
    background-color: #f5f5f5;
    padding: 15px 0;
    margin-right: -15px;
  }
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
}
.productitem:hover, .productitem:focus {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
.productitem.ribbon:before {
  width: 100px;
  height: 39px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 160px;
  left: 0;
  pointer-events: none;
}
.productitem.actie:before {
  background: url("../../web/img/ribbons/actie.png") no-repeat;
}
.productitem.binnenkort-verkrijgbaar:before {
  background: url("../../web/img/ribbons/binnenkort-verkrijgbaar.png") no-repeat;
}
.productitem.binnenkort-weer-op-voorraad:before {
  background: url("../../web/img/ribbons/binnenkort-weer-op-voorraad.png") no-repeat;
}
.productitem.dagdeal:before {
  background: url("../../web/img/ribbons/dagdeal.png") no-repeat;
}
.productitem.extra-bestelinformatie:before {
  background: url("../../web/img/ribbons/extra-bestelinformatie.png") no-repeat;
}
.productitem.gratis-bezorgd:before {
  background: url("../../web/img/ribbons/gratis-bezorgd.png") no-repeat;
}
.productitem.meest-gekozen:before {
  background: url("../../web/img/ribbons/meest-gekozen.png") no-repeat;
}
.productitem.morgen-in-huis:before {
  background: url("../../web/img/ribbons/morgen-in-huis.png") no-repeat;
}
.productitem.nergens-goedkoper:before {
  background: url("../../web/img/ribbons/nergens-goedkoper.png") no-repeat;
}
.productitem.niet-meer-leverbaar:before {
  background: url("../../web/img/ribbons/niet-meer-leverbaar.png") no-repeat;
}
.productitem.nieuw:before {
  background: url("../../web/img/ribbons/nieuw.png") no-repeat;
}
.productitem.onze-keuze:before {
  background: url("../../web/img/ribbons/onze-keuze.png") no-repeat;
}
.productitem.op-is-op:before {
  background: url("../../web/img/ribbons/op-is-op.png") no-repeat;
}
.productitem.pre-order:before {
  background: url("../../web/img/ribbons/pre-order.png") no-repeat;
}
.productitem.tijdelijke-aanbieding:before {
  background: url("../../web/img/ribbons/tijdelijke-aanbieding.png") no-repeat;
}
.productitem.tijdelijk-niet-leverbaar:before {
  background: url("../../web/img/ribbons/tijdelijk-niet-leverbaar.png") no-repeat;
}
.productitem.top-aanbieding:before {
  background: url("../../web/img/ribbons/top-aanbieding.png") no-repeat;
}
.productitem.tweede-kans:before {
  background: url("../../web/img/ribbons/tweede-kans.png") no-repeat;
}
.productitem.uit-onze-showroom:before {
  background: url("../../web/img/ribbons/uit-onze-showroom.png") no-repeat;
}
.productitem.weekenddeal:before {
  background: url("../../web/img/ribbons/weekenddeal.png") no-repeat;
}
.productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 200px;
}
.productitem .card-body {
  padding: 1rem;
}
.productitem .card-body .productitem__description span {
  display: table-row;
  font-size: 14px;
  line-height: 19px;
}
.productitem .card-body .productitem__description span:before {
  content: "\f00c";
  width: 1.3em;
  font-size: 16px;
  line-height: 24px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 0;
  color: #519872;
}
.productitem .card-body p {
  margin-bottom: 0;
}
.productitem .card-footer {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  padding: 1rem;
  border-top: 0;
}
.productitem .card-footer--align .col-md-12 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}
.productitem__title {
  font-size: 17px;
  padding-bottom: 5px;
}
.productitem__discountslogan {
  color: #519872;
  font-weight: 600;
}
.productitem__baseprice > .price {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: #EE0000;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #EE0000;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 2px;
}
.productitem__nowprice > span {
  display: inline-block;
  font-size: 21px;
  font-weight: 400;
  color: #000;
}
@media (max-width: 565px) {
  .productitem__nowprice > span {
    margin-bottom: 10px;
  }
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem .btn {
  font-size: 16px;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}

.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  padding: 15px;
  border: 1px solid #eee;
}
.categoryitem:hover, .categoryitem:focus {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
.categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 170px;
}
.categoryitem .card-body {
  padding: 0;
  text-align: center;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.categoryitem__title {
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: 600;
}
.categoryitem .btn {
  font-size: 16px;
}