html.webshop--ishomepage .main--margintop {
  margin-top: 0;
  width: 100%;
}
html.webshop--ishomepage #product_listing {
  padding: 30px 15px 15px 15px;
  margin-left: 0;
  margin-right: 0;
}
html.webshop--ishomepage .webshop-products--ordering-container {
  display: none;
}
@media (max-width: 576px) {
  html.webshop--ishomepage #product_listing {
    margin: 0;
  }
  html.webshop--ishomepage #product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

html.webshop--ishomepage main > * {
  max-width: 100%;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}

html.webshop--ishomepage main .twocol .col-md-6:nth-child(1) p {
  margin-bottom: 0;
}

html.webshop--ishomepage main .twocol .col-md-6:nth-child(1) {
  padding-right: 0;
}

html.webshop--ishomepage main .twocol .col-md-6:nth-child(1) img {
  width: 100%;
}

html.webshop--ishomepage main .twocol .col-md-6:nth-child(2) {
  max-width: 555px;
  padding-left: 85px;
}

@media (max-width: 600px) {
  html.webshop--ishomepage main .twocol .col-md-6:nth-child(1) {
    padding-right: 15px;
  }
  html.webshop--ishomepage main .twocol .col-md-6:nth-child(2) {
    padding: 50px 0;
  }
  html.webshop--ishomepage main .twocol .col-md-6:nth-child(2) {
    padding: 13%;
  }
}