@charset "UTF-8";
.tab-section:before {
  display: block;
  content: " ";
  height: 150px; /* Give height of your fixed element */
  margin-top: -150px; /* Give negative margin of your fixed element */
  visibility: hidden;
}

.product__info #product_tab3 a {
  font-weight: 600;
}

.product__options .card {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.product__options .card .card-body {
  padding: 30px;
}
.product__options .card .card-body .product__quantitydiscounts {
  padding: 5px;
  font-size: 15px;
  line-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.product__options .card .card-body .webshop-product__optionlabel {
  font-weight: 600;
}
.product__options .card .card-body .webshop-product__option .form-group {
  max-width: 100%;
}
.product__options .card .card-footer .webshop-productusps ul.checkmarks {
  margin-bottom: 0;
}
.product__options .card .card-footer .webshop-productusps ul.checkmarks li:before {
  content: "●";
  color: #000000;
  font-size: 12px;
  position: relative;
  top: -3px;
}
.product__options .card .card-footer ul.checkmarks strong, .product__options .card .card-footer ul.checkmarks b {
  color: #2786e8;
}
.product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.product__options .webshop-product__optiontitle {
  float: left;
  width: 100px;
}
.product__options .webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
}
.product__options .product__shortdescription span {
  display: table-row;
}
.product__options .product__shortdescription span:before {
  content: "\f00c";
  width: 1.3em;
  font-size: 16px;
  line-height: 24px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 0;
  color: #519872;
}
.product__options .webshop-product__priceblock {
  margin-top: 10px;
}
.product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #ed300a;
  position: relative;
}
.product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid red;
  border-radius: 0;
  transform: rotate(-11deg);
}
.product__options .webshop-product__price {
  font-size: 28px;
  font-weight: 400;
  color: #000;
}
.product__options .product__discountslogan {
  margin-top: 15px;
  color: #75b325;
  font-weight: 700;
}
.product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__info .nav-link {
  color: #000;
  font-weight: 400;
}
.product__info .tab-section {
  margin-bottom: 50px;
}
.product__info .tab-section .reviewitem {
  padding: 20px 0 10px 0;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.125);
}
.product__info .tab-section .reviewdata__shopscore {
  font-size: 18px;
  font-weight: 600;
}
.product__info h2 {
  font-size: 21px;
  font-weight: 400;
}
.product__info .nav-pills {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.product__info .nav-pills .nav-link {
  padding: 1rem;
}

#product__images__preview__container {
  max-height: 640px;
  overflow: hidden;
}
#product__images__preview__container .product__images__preview {
  overflow: hidden;
}

.product__images__grid {
  margin-top: 10px;
  max-height: 100px;
  margin-bottom: 10px;
  overflow: hidden;
}
.product__images__grid .product__image img {
  max-height: 100px;
  width: auto;
}
@media (max-width: 1200px) {
  .product__images__grid .product__image img {
    max-height: 70px;
  }
}
@media (max-width: 767px) {
  .product__images__grid .product__image img {
    max-height: 54px;
  }
}
.product__images__grid .product__image:hover {
  cursor: pointer;
}
.product__images__grid .slick-slide {
  margin-right: 10px;
  opacity: 0.6;
}
.product__images__grid .slick-slide img {
  border: 2px solid transparent;
}
.product__images__grid .slick-slide.slick-current {
  opacity: 1;
}
.product__images__grid .slick-slide.slick-current img {
  border: 2px solid #ccc;
}

.slick-track {
  margin-left: 0;
  margin-right: auto;
}

@media (max-width: 577px) {
  .product__images {
    margin: 20px 0;
  }
  .product__images__preview img {
    max-height: 250px;
    width: auto;
  }
  .product__images .slick-next,
  .product__images .slick-prev {
    top: 100%;
    margin-top: 15px;
    z-index: 1;
  }
  .product__images .slick-next {
    right: 0px;
  }
  .product__images .slick-prev {
    left: 0px;
  }
}
.slick-next:before, .slick-prev:before {
  color: #8fc13e;
}

.product__tags {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product__tag {
  background: transparent;
  padding: 6px 10px;
  display: inline-block;
  margin: 2px;
  color: #bbb;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  text-decoration: none;
}

.product__tag:hover {
  background: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.product__related {
  margin-top: 15px;
}
.product__related .webshop-products--ordering-container {
  display: none;
}

@media (min-width: 480px) and (max-width: 979px) {
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .product__options {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .product__options {
    position: -webkit-sticky;
    position: sticky;
    top: 125px;
  }
}
@media (min-width: 320px) and (max-width: 979px) {
  .productitem .btn {
    visibility: visible;
  }
}
.webshop-product__option--hidden,
.webshop-product__option--hiddendefault {
  display: none;
}